import React from 'react';
import * as ra from 'react-admin';
import { Card, Typography, CircularProgress } from '@material-ui/core';

import { LoloList } from 'react-admin-lolo';

const ExpandPanel = props => {
	return (
    <Card style={cardStyle}>
      <Typography
      	variant='h5' 
      	style={headerStyle}
      	>
      	VPN Tunnels
     	</Typography>
      <ra.ReferenceManyField
        reference='vpn-tunnels'
        target='gatewayId'
        addLabel={false}
        {...props}
        >
        <ra.Datagrid rowClick='edit'>
        	<ra.TextField label='Name' source='name' />
          <ra.TextField label='AZ' source='az' />
          <ra.TextField label='Type' source='type' />
          <ra.TextField
          	label='Status'
          	emptyText={<CircularProgress size={16} style={{marginTop: 4}}/>}
          	>
        	</ra.TextField>
          <ra.DateField source='createdAt' />
        </ra.Datagrid>
      </ra.ReferenceManyField>
    </Card>
	);
};

const ListGateways = props => (
	<LoloList
		expand={ExpandPanel} {...props} 
	/>
);

const cardStyle = {
	padding: 10,
	marginLeft: -10,
	width: '100%'
};

const headerStyle = {
	margin: '0px 5px 5px 5px',
	paddingBottom: 5,
	fontSize: 16,
	borderBottom: '1px solid rgb(224, 224, 224)'
};

export default ListGateways;