import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { DashboardMenuItem, MenuItemLink } from 'react-admin';

import SubMenu from './SubMenu';
import SubMenuIcon from '@material-ui/icons/ChevronRight';

import EnterpriseIcon from '@material-ui/icons/BusinessOutlined';
import CSPIcon from '@material-ui/icons/SimCardOutlined';
import APNIcon from '@material-ui/icons/BookmarkBorderOutlined';
import APNGrantIcon from '@material-ui/icons/SecurityOutlined';
import APNGroupIcon from '@material-ui/icons/BookmarksOutlined';

import DeviceGroupIcon from '@material-ui/icons/GroupOutlined';
import DeviceIcon from '@material-ui/icons/PermIdentityOutlined';
import DeviceIdIcon from '@material-ui/icons/LoyaltyOutlined';
import DNSRecordIcon from '@material-ui/icons/DnsOutlined';
import ACLIcon from '@material-ui/icons/TrafficOutlined';
import AggregationIcon from '@material-ui/icons/AvTimerOutlined';
import AutomationIcon from '@material-ui/icons/FlashAutoOutlined';

import SubnetIcon from '@material-ui/icons/GroupWorkOutlined';
import SessionIcon from '@material-ui/icons/AdjustOutlined';
import RadTestIcon from '@material-ui/icons/DevicesOutlined';

import GeofenceIcon from '@material-ui/icons/CropFreeOutlined';
import GeofenceGroupIcon from '@material-ui/icons/LanguageOutlined';

import GatewayIcon from '@material-ui/icons/RouterOutlined';
import VPNIcon from '@material-ui/icons/VpnLockOutlined';

const Menu = ({ onMenuClick, dense, logout }) => {
  const [state, setState] = useState({
    menuOperator: false,
    menuEnterprise: false,
    menuDebug: false
  });

  const open = useSelector(state => state.admin.ui.sidebarOpen);
  useSelector(state => state.theme); // force rerender on theme change

  const handleToggle = (menu: MenuName) => {
    setState(state => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <div style={{marginTop: 10}}>
      {' '}
      <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
      <SubMenu name="PVPC"
        handleToggle={() => handleToggle('menuOperator')}
        isOpen={state.menuOperator}
        sidebarIsOpen={open}
        icon={<SubMenuIcon />}
        dense={dense} >
        <MenuItemLink to={`/enterprises`}
          primaryText='Enterprises'
          leftIcon={<EnterpriseIcon/>}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense} />
        <MenuItemLink to={`/csps`}
          primaryText='CSPs'
          leftIcon={<CSPIcon/>}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense} />
        <MenuItemLink to={`/apns`}
          primaryText='DNNs'
          leftIcon={<APNIcon/>}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense} />
        <MenuItemLink to={`/apn-grants`}
          primaryText='DNN Grants'
          leftIcon={<APNGrantIcon/>}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense} />
        <MenuItemLink to={`/apn-groups`}
          primaryText='DNN Groups'
          leftIcon={<APNGroupIcon/>}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense} />
      </SubMenu>

      <SubMenu name="Enterprise"
        handleToggle={() => handleToggle('menuEnterprise')}
        isOpen={state.menuEnterprise}
        sidebarIsOpen={open}
        icon={<SubMenuIcon />}
        dense={dense} >
        <MenuItemLink to={`/device-groups`}
          primaryText='Device Groups'
          leftIcon={<DeviceGroupIcon/>}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense} />
        <MenuItemLink to={`/devices`}
          primaryText='Devices'
          leftIcon={<DeviceIcon/>}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense} />
        <MenuItemLink to={`/device-ids`}
          primaryText='Device Identities'
          leftIcon={<DeviceIdIcon/>}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense} />
        <MenuItemLink to={`/dns-records`}
          primaryText='DNS Records'
          leftIcon={<DNSRecordIcon/>}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense} />
        <MenuItemLink to={`/acls`}
          primaryText='Access Lists'
          leftIcon={<ACLIcon/>}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense} />
        <MenuItemLink to={`/aggregations`}
          primaryText='Aggregations'
          leftIcon={<AggregationIcon/>}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense} />
        <MenuItemLink to={`/automations`}
          primaryText='Automations'
          leftIcon={<AutomationIcon/>}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense} />
        <MenuItemLink to={`/geofences`}
          primaryText='Geofences'
          leftIcon={<GeofenceIcon/>}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense} />
        <MenuItemLink to={`/geofence-groups`}
          primaryText='Geofence Groups'
          leftIcon={<GeofenceGroupIcon/>}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense} />
        <MenuItemLink to={`/gateways`}
          primaryText='Gateways'
          leftIcon={<GatewayIcon/>}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense} />
        <MenuItemLink to={`/vpn-tunnels`}
          primaryText='VPN Tunnels'
          leftIcon={<VPNIcon/>}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense} />
      </SubMenu>

      <SubMenu name="System"
        handleToggle={() => handleToggle('menuDebug')}
        isOpen={state.menuDebug}
        sidebarIsOpen={open}
        icon={<SubMenuIcon />}
        dense={dense} >
        <MenuItemLink to={`/subnets`}
          primaryText='Subnets'
          leftIcon={<SubnetIcon/>}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense} />
        <MenuItemLink to={`/sessions`}
          primaryText='Sessions'
          leftIcon={<SessionIcon/>}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense} />
        <MenuItemLink to={`/radtest`}
          primaryText='RADIUS client'
          leftIcon={<RadTestIcon/>}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense} />
      </SubMenu>
    </div>
  );
};

export default Menu;
