import React from "react";
import { LoloAdmin, LoloResource } from 'react-admin-lolo';
import { Route } from 'react-router-dom';
import * as ra from 'react-admin';

import { Dashboard  } from './dashboard';
import RadTest from './RadTest';
import ShowDevice from './ShowDevice';

import EditGeofence from './geofence/Edit';
import EditGeofenceGroup from './geofence-group/Edit';

import ListGateways from './gateway/ListGateways';

import Layout from './layout';
import './App.css';

const App = () => (
  <LoloAdmin
    apiUrl={process.env.REACT_APP_API_URL}
    dashboard={Dashboard}
    title='Lolo PVPC'
    layout={Layout}
    customRoutes={[
      <Route exact path="/radtest" component={RadTest} />
    ]}
    >
    <LoloResource
      name='enterprises'
    />
    <LoloResource
      name='csps'
    />
    <LoloResource
      name='apns'
    />
    <LoloResource
      name='apn-groups'
    />
    <LoloResource
      name='apn-grants'
    />
    <LoloResource
      name='egress-gateways'
    />
    <LoloResource
      name='device-groups' 
    />
    <LoloResource
      name='devices'
      show={ShowDevice}
      createWithId={true}
    />
    <LoloResource
      name='device-ids'
    />
    <LoloResource
      name='dns-records'
    />
    <LoloResource
      name='acls'
    />
    <LoloResource
      name='aggregations'
    />
    <LoloResource
      name='automations'
    />
    <LoloResource
      name='geofences'
      edit={EditGeofence}
      create={EditGeofence}
    />
    <LoloResource
      name='geofence-groups'
      edit={EditGeofenceGroup}
      create={EditGeofenceGroup}
    />
    <LoloResource
      name='subnets'
      edit={null}
      create={null}
    />
    <LoloResource
      name='sessions'
      edit={null}
      create={null}
    />
    <LoloResource
      name='gateways'
      list={ListGateways}
    />
    <LoloResource
      name='vpn-tunnels'
    />
    <LoloResource
      name='ike-policy-templates'
    />
    <LoloResource
      name='ipsec-policy-templates'
    />
  </LoloAdmin>
);

export default App;